import { RobotsEnum } from './types'
import { mergeMeta } from 'theme/meta/utils/mergeMeta';
import { relRobotsHandler } from 'theme/meta/utils/robots';
import config from 'config';
import { regionMeta } from 'theme/meta/utils/region';
import { isRegionalPage } from '$modules/shipping/utils/region-url';
import i18n from '@vue-storefront/i18n'

export function getMetaContent (category, meta, route) {
  let metaTitle
  let metaDescription
  let metaKeywords

  if (category) {
    metaTitle = category.meta_title
    metaDescription = category.meta_description
    metaKeywords = category.meta_heywords
  } else {
    metaTitle = meta.category_seo_meta_title
    metaDescription = meta.category_seo_meta_description
    metaKeywords = meta.category_seo_meta_keywords
  }

  const regionalMeta = regionMeta(route?.fullPath, metaTitle || i18n.t('Stock'))

  return {
    title: regionalMeta?.title || `${i18n.t('Stock')} | VARUS`,
    description: metaDescription || `${i18n.t('Stock')} ${i18n.t('PROMOTION_DESCRIPTION')}`,
    keywords: metaKeywords || i18n.t('Stock'),
    region: regionalMeta?.region
  }
}

export function metaPromotion () {
  let relRobots = relRobotsHandler(RobotsEnum.NOINDEXNOFOLLOW, config.xEnv.key);

  const metaContent = getMetaContent(
    this.getSelectedCategory,
    this.getPromotionMetaData,
    this.$route
  )

  if (isRegionalPage(this.$route.fullPath) && !metaContent?.region?.is_indexed) {
    relRobots = relRobotsHandler(RobotsEnum.NOINDEXNOFOLLOW, config.xEnv.key);
  }

  const meta = {
    title: metaContent.title,
    meta: {
      ...relRobots,
      description: {
        name: 'description',
        content: metaContent.description
      },
      keywords: {
        name: 'keywords',
        content: metaContent.keywords
      }
    }
  };

  return mergeMeta(meta)
}
